<template>    
    <div>

      <ArtistCancellationModal
        :bid-id="item ? item.id : null"
        :cancellation-reasons="cancellationReasons"
        :show-modal="showCancellationModal"
        @show-cancellation-modal="showCancellationModal = $event"
        @booking-cancelled="cancelBooking">
      </ArtistCancellationModal>
      
        <div class="details-view p-4">
            <h3 v-if="item && item.gig && item.gig.venue">Gig: {{item.gig.venue.name}} at {{this.fullDate(item.gig.start)}}  </h3>
            <h4 v-if="item && item.artist">{{item.artist.name}}</h4>

            <InlineNotification Heading="Gig instructions" >
                <template slot="body">
                    <p class="mb-4">
                    
                        <DictionaryContent :hookId="11" :dynamicUrl="'artist/offers'">
                            Double-check it. Here’s a refresher of the venue’s instructions for the gig. Before the venue’s booked you, just make sure you’re happy with everything.                      
                        </DictionaryContent>
 
                    </p>             
                </template>
            </InlineNotification>

            <div class="rounded-md  p-4 max-w-4xl" :class="(item && (item.cancelled || item.gig.cancelled || gigCancelled)) ? 'bg-red-100' : 'bg-green-50'">

                <div class="flex">
                    <div class="flex-shrink-0">
                    <!-- Heroicon name: solid/check-circle -->
                    </div>
                    <div class="ml-3">
                        <div class="grid grid-cols-1 text-sm" v-if="item">    
                            <span>
                                <strong>Fee:</strong> {{item.gig.currency.symbol}}{{item.fee}} {{ item.artist.vat_number ? '+ VAT' : '' }}
                            </span> 
                            <span v-if="item.date_bid">
                                <strong>You gave your availability on:</strong> {{item.date_bid}} 
                            </span>
                            <span v-if="item.notified_venue_ok_to_artist">
                                <strong>Venue confirmed your availability:</strong> {{item.notified_venue_ok_to_artist}} 
                            </span>
                            <span v-if="item.accepted && !item.artist_final_accepted" class="mt-4">
                                <strong>{{item.gig.venue.name}} has said they want you on the gig.</strong><br/> 
                                <strong v-if="!item.artist_final_accepted"> You must now make your final confirmation below to be booked onto the gig.</strong> 
                            </span>
                          <div v-if="gigCancelled" class="mt-4">
                            <span>
                                <strong class="uppercase text-lg">{{ cancellationText }}</strong><br/>
                            </span>
                            <span v-if="cancellationReasonText.length " class="mt-4">
                                <strong class="">Reason for cancellation: </strong> {{ cancellationReasonText }}
                            </span>
                          </div>

                            <span v-else-if="item.artist_final_accepted && !item.cancelled && !item.gig.cancelled" class="mt-4">
                                <strong>This booking is now complete, you are officially on the lineup.</strong><br/> 
                                   
                                <span class="text-sm">
                                    <label>Payment Enquiries: </label>
                                    <a :href="'mailto:' + item.gig.venue.billing_email">{{item.gig.venue.billing_email}}</a>
                                </span>
                            </span>
                            <span v-else-if="item.cancelled " class="mt-4">
                                <strong class="text-lg uppercase">You have been removed from the lineup.</strong><br/> 
                            </span>
                            <span v-else-if="item.gig.cancelled " class="mt-4">
                                <strong class="text-lg uppercase">This gig has been cancelled.</strong><br/> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-4xl p-4 rounded-md bg-green-50"  v-if="item && item.accepted && item.gig && item.gig.is_past">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <CurrencyPoundIcon class="w-5 h-5 text-green-400" />
                    </div>
                    <div class="ml-3 text-sm">
                        <div  v-if="this.item.invoice_id">   
                            <p>Your invoice can now be viewed via the <router-link to="/artist/invoices" class="underline">invoice section</router-link>.</p>
                            <p v-if="item.gig.venue.billing_email">If you have queries about your invoice, please contact <a class="underline" :href="'mailto:' + item.gig.venue.billing_email" v-html="item.gig.venue.billing_email"></a></p>
                        </div>
                        <div v-else>
                            This gig is awaiting venue sign-off.  Once signed-off a copy of your invoice will be available to download from the invoices section.
                        </div>
                    </div>
                </div>
            </div>

            <div  v-if="item && item.gig">
                <div class=" lg:max-w-5xl"> 

                    <form class="form text-sm mb-56 space-y-4 lg:mb-36" ref="form" @submit.prevent="false" novalidate>

                        <div  class="col-span-8 input-group">
                            <label>Date</label>
                            {{this.fullDate(item.gig.start)}} until {{this.fullDate(item.gig.end)}} 
                        </div>

                      <div  v-if="!gigCancelled && false" class="instructions col-span-2 grid grid-flow-row gap-4 input-group" >
                        <button @click.prevent="showCancellationModal = true" class="flex justify-center items-center pink bg-gp_pink-default-500 hover:bg-gp_pink-default-400 text-white text-sm font-bold py-1 px-4 rounded-3xl">
                          Cancel Booking
                        </button>
                      </div>

                        <div  class="col-span-8 input-group" >
                            <label>Venue</label>
                            {{item.gig.venue.name}}<br/>
                            {{item.gig.venue.address.address_line_1}}<br/>
                            {{item.gig.venue.address.address_line_2}}<br/>
                            {{item.gig.venue.address.town}}<br/>
                            {{item.gig.venue.address.county}}<br/>
                            {{item.gig.venue.address.post_code}}<br/>
                            <label class="mt-4">Telephone</label>
                            {{item.gig.venue.telephone}}<br/>
                        </div>                            
                        <div  class="grid grid-flow-row col-span-8 gap-4 instructions input-group" >
                            <h2 class="text-sm font-bold uppercase">Instructions and equipment at the venue</h2>
                            
                            <div v-if="item.gig.equipment_venue"> 
                                {{item.gig.equipment_venue}}
                            </div>
                            <div v-if="item.gig.equipment_artist">
                                <h3>Equipment the artist needs to bring</h3>
                                {{item.gig.equipment_artist}}
                            </div>  
                            <div v-if="item.gig.repertoire">
                                <h3>Repertoire</h3>
                                {{item.gig.repertoire}}
                            </div>
                            <div v-if="item.gig.dress_code">
                                <h3>Dress code</h3>
                                {{item.gig.dress_code}}
                            </div>
                            <div v-if="item.gig.parking_details">
                            
                                <h3>Parking details</h3>
                                {{item.gig.parking_details}}
                            </div>
                            <div v-if="item.gig.load_in_time">              
                                <h3>Load in times</h3>
                                {{item.gig.load_in_time}}
                            </div>
                            <div  v-if="item.gig.sound_check_time">
                            
                                <h3>Soundcheck times</h3>
                                {{item.gig.sound_check_time}}
                            </div>
                            <div  v-if="item.gig.performance_times">
                                
                                <h3>Performance times</h3>
                                {{item.gig.performance_times}}
                            </div>
                            <div v-if="item.gig.additional_notes">

                                <h3>Additional notes</h3>
                                {{item.gig.additional_notes}}
                            </div>

                          <div v-if="item.gig.briefs">

                            <h3>Briefs</h3>
                            <span v-html="item.gig.briefs"></span>
                          </div>

                          <div v-if="item.gig.invoicing">
                            <h3>Invoicing</h3>
                            
                                {{item.gig.invoicing}} 
                            </div>
                            <div v-if="item.gig.important">

                                <h3 class="uppercase">Important information</h3>
                                    {{item.gig.important}}
                            </div>
                            <div v-if="item.gig.payment_terms">

                                <h3>Payment terms</h3>
                                {{item.gig.payment_terms}}
                            </div> 
                        </div>

                    </form>

                    <div class="action-bar" v-if="item.date_bid && item.declined && !item.cancelled && !item.gig.cancelled">
                        <button @click="initial_accept()" class="justify-center text-white bg-black cta icon ">
                        <ThumbUpIcon class="mr-2"/>
                            Accept Availability
                        </button>
                    </div>
                    <div class="action-bar" v-if="item.date_bid && !item.declined & !item.artist_final_accepted && !item.cancelled && !item.gig.cancelled">
                        <button @click="availability_decline()" class="justify-center text-white bg-black cta icon ">
                        <ThumbDownIcon class="mr-2"/>
                        Decline Availability
                        </button>
                    </div>
                    <div class="justify-between action-bar"  v-if="item.notified_initial_artist == null && item.date_bid == null && item.gig.status != 'CONFIRMED'">  
                        <router-link to="/artist/offers" class="hidden lg:inline cta bg-gp_pink-default-500 justify-self-start ">Back to list</router-link>    
                        <div class="flex flex-col items-end w-full space-x-2 space-y-2 lg:flex-row lg:w-1/2" > 
                            <button @click="initial_accept()"  class="bg-indigo-600 cta icon" ><ThumbUpIcon  class="mr-2"/>
                            
                                <DictionaryContent :hookId="12" :dynamicUrl="'artist/offers'">
                                    Confirm availability based on {{item.gig.currency.symbol}}{{form.fee}} offer
                                </DictionaryContent> 
                            

                            </button>
                            <button @click="decline()" class="text-white bg-black cta icon"><ThumbDownIcon class="mr-2" />
                            
                                <DictionaryContent :hookId="13" :dynamicUrl="'artist/offers'">
                                    Decline offer
                                </DictionaryContent>
                            
                            </button>
                    
                        </div>
                    </div>            
                    <div class="justify-between action-bar" v-else-if="showActionBar">              
                        <router-link to="/artist/offers" class="hidden lg:inline cta bg-gp_pink-default-500 justify-self-start ">Back to list</router-link> 
                        <button @click="final_accept()"  class="justify-center bg-indigo-600 cta icon" >
                        <ThumbUpIcon class="mr-4"/>
                            Book onto this gig
                        </button>   
                        <button @click="final_decline()" class="text-white bg-black cta icon "><ThumbDownIcon class="mr-2" />Decline offer</button>                          
                    </div>
                </div>
            </div>
        </div>
        <NotificationSmall ref="notification" />
    </div>
</template>

<style>
    .form label 
    {
        @apply font-bold;
    }
</style>

<script> 
import Pristine from '../../../utils/pristine.js'
import { apiComputed, apiMethods } from '@/state/helpers';  
import NotificationSmall from '../../../components/notifications/small.vue';  
import InlineNotification from '../../../components/notifications/inline.vue'; 
import fullDate from  '@/utils/format-date-full.js';
import format from  '@/utils/format-date.js';
import { canBidBeAccepted } from '@/state/models/bidStatus'
import { CurrencyPoundIcon, ThumbUpIcon, ThumbDownIcon } from "@vue-hero-icons/solid";
import DictionaryContent from '../../../components/dictionary/DictionaryContent.vue';
import ArtistCancellationModal from '@/components/GigCancellations/ArtistCancellationModal.vue';

export default {
    name: "ArtistBidDetails",
    components:{  
        NotificationSmall,
        InlineNotification, 
        CurrencyPoundIcon,
        ThumbUpIcon,
        ThumbDownIcon, 
        DictionaryContent,
        ArtistCancellationModal
    }, 
    data () {
 
       return {
            item: null,
            rowHeight: 5,
            is_enabled: false,
            is_default: false, 
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            }, 
            form: {
                name: '',
                 
            },
         cancellationReasons: [],
         showCancellationModal: false,
       }
    },
    created() {  

        this.getArtistBid(this.$attrs.id).then((resp) => { 
            this.item = resp;
            this.form = resp;
            console.log('resp', resp);
        });     
    },
    mounted(){

      this.getGigCancellationReasons(['artist', 'all']).then((resp) => {
        this.cancellationReasons = resp.data.data;
      });

        if (this.$refs.form)
        {
            this.pristine = new Pristine(this.$refs.form, this.pristineConfig); 
        }

        if (this.item)
        {
            let deepCopy = JSON.parse(JSON.stringify(this.item));            
            this.form = deepCopy; 
        }
    },
    computed: {     
        ...apiComputed,
        showActionBar(){
            return canBidBeAccepted(this.item.status, this.item.gig.status) && this.item.accepted && !this.item.artist_final_accepted;
        },
      gigCancelled() {
          return this.item.venue_cancelled || this.item.artist_cancelled;
      },
      cancellationText() {
        if (this.item.venue_cancelled) return 'The venue has cancelled this booking';
        if (this.item.artist_cancelled) return 'You cancelled this booking.';
        if (this.item.gig.cancelled) return 'The venue has cancelled this gig.';
        return '';
      },
      cancellationReasonText() {
        if (this.item.cancellation_reason) {
          if (this.item.cancellation_reason_other) return this.item.cancellation_reason_other;
          if (this.item.cancellation_reason) return this.item.cancellation_reason.reason;
        }

        if (this.item.gig.cancellation_reason) {
          if (this.item.gig.cancellation_reason_other) return this.item.gig.cancellation_reason_other;
          if (this.item.gig.cancellation_reason) return this.item.gig.cancellation_reason.reason;
        }

        return '';
      }
    },
    methods:{
        format,
        fullDate,
        ...apiMethods,
        cancelBooking(params) {
          this.requestBookingCancellation({bidId: this.item.id,  reason_id: params.reason, other: params.other}).then(() => {
            this.showCancellationModal = false
            this.item.artist_cancelled = true
          })
        },
        final_accept(){
            
            let payload = this.form;
            this.acceptFinal(payload)
                .then(() => {
                    this.$notify('Booking complete');
                    this.$router.push('/artist/gigs');
                });
        },
        final_decline(){
            let payload = this.form;
            this.declineArtistFinal(payload)
                .finally(() => {
                    this.$notify('Final confirmation declined');
                    this.$router.push('/artist/gigs');
                });
        },
        availability_decline(){
            let payload = this.form;
            this.declineArtistFinal(payload)
                .finally(() => {
                this.$notify('Availability declined');
                this.$router.push({name: 'AwaitingVenue'});
                });
        },
        decline(){
            let payload = this.form;
            payload.declined = true;
            this.updateArtistBid(payload)
                .then(() => {
                    this.$notify('Bid declined');
                    this.$router.push('/artist/offers');
                });
        },
        initial_accept(){
                                
            let payload = this.form;
            this.initialArtistAcceptance(payload)
                .then(response => {

                        console.log("update response", response);
                    
                    this.$notify("Offer sent to venue to accept/decline");
                    this.$router.push('/artist/offers');

                    }).catch((error) => {
                    alert(error);
            });  
        },
    }
}
</script>

