/* eslint-disable no-empty-pattern */
import axios from "../../utils/client";
import Vue from "vue";
import { Session } from "@/utils/session";
import largeDataSets from "../const.js";
import { DateTime } from "luxon";

export const state = {
    regions: getSavedState("api.regions"),
    venueTags: getSavedState("api.venueTags"),
    venues: getSavedState("api.venues"),
    domainBrand: getSavedState("api.domainBrand"),
    gigs: getSavedState("api.gigs"),
    gigtemplates: getSavedState("api.gigtemplates"),
    artisttags: getSavedState("api.artisttags"),
    artistbids: getSavedState("api.artistbids"),
    uuid: getSavedState("api.uuid"),
    gigbids: getSavedState("api.gigbids"),
    tiers: getSavedState("api.tiers_band"),
    artistdirectory: getSavedState("api.artistdirectory_20220817"),
    artists: getSavedState("api.artists"),
    privateVenueRequests: [],
    hasShowArtistMonthlyInvoices: false
}

function setLoginStates({commit}, response) {
    commit('SET_UUID', response.data.data.uuid);
    commit("SET_REGIONS", response.data.data.regions);
    commit("SET_VENUETAGS", response.data.data.venueTags);
    commit("SET_ARTISTTAGS", response.data.data.artistTags);
}

export const mutations = {
    SHOW_ARTIST_MONTHLY_INVOICES(state, show) {
        state.hasShowArtistMonthlyInvoices = show;
    },
    SET_PRIVATE_VENUE_REQUESTS(state, requests) {
        state.privateVenueRequests = requests;
    },
    SET_REGIONS(state, regions) {
        state.regions = regions;
        saveState("api.regions", regions);
    },
    SET_VENUETAGS(state, venueTags) {
        state.venueTags = venueTags;
        saveState("api.venueTags", venueTags);
    },

    SET_VENUES(state, venues) {
        state.venues = venues;
        saveState("api.venues", venues);
    },
    SET_DOMAIN_BRAND(state, brand) {
        state.domainBrand = brand;
        saveState("api.domainBrand", brand);
    },
    SET_TIERS(state, tiers) {
        state.tiers = tiers;
        saveState("api.tiers_band", tiers);
    },
    SET_ARTIST_DIRECTORY(state, directory) {
        //console.log("directorystate", directory);
        state.artistdirectory = directory;
        const today = DateTime.now()
            .setLocale("en-gb")
            .toLocaleString()
            .replace(/\//g, "");
        saveState(`api.artistdirectory_${today}`, directory);
    },
    SET_SMALL_ARTIST_DIRECTORY(state, directory) {
        state.artistdirectory = directory;
        const today = DateTime.now()
            .setLocale("en-gb")
            .toLocaleString()
            .replace(/\//g, "");
        saveState(`api.small_artistdirectory_${today}`, directory);
    },
    SET_BOOKINGS(state, bookings) {
        state.bookings = bookings;
        saveState("api.bookings", bookings);
    },

    SET_EVENTS_PROGRESS(state, progress) {
        state.progress = progress;
        saveState("api.progress", progress);
    },

    SET_ARTISTTAGS(state, artisttags) {
        console.log("saving state", artisttags);
        state.artisttags = artisttags;
        saveState("api.artisttags", artisttags);
    },

    SET_GIGS(state, gigs) {
        state.gigs = gigs;
        saveState("api.gigs", gigs);
    },

    SET_UUID(state, uuid) {
        state.uuid = uuid;
        saveState("api.uuid", uuid);
    },
    SET_GIG_TEMPLATES(state, gigtemplates) {
        state.gigtemplates = gigtemplates;
        saveState("api.gigtemplates", gigtemplates);
    },
    SET_GIG_TEMPLATE(state, gigtemplate) {
        let index = state.gigtemplates.findIndex(
            (v) => v.id === gigtemplate.id
        );

        if (index !== -1) {
            Vue.set(state.gigtemplates, index, gigtemplate);
            //    console.log('SET_GIG_TEMPLATE Vue set', state.gigtemplates)
        } else {
            state.gigtemplates.splice(
                state.gigtemplates.length,
                0,
                gigtemplate
            );
            //    console.log('SET_GIG_TEMPLATE splice', state.gigtemplates)
            // console.log('UPDATE_TEAMS action failed with: ', newNotification)
        }
    },
    SET_ARTISTS(state, artists) {
        state.artists = artists;
        saveState("api.artists", artists);
    },
    SET_ARTIST(state, artist) {
        if (state.artists) {
            let index = state.artists.findIndex((v) => v.id === artist.id);
            state.artist = artist;
            if (index !== -1) {
                Vue.set(state.artists, index, artist);
            } else {
                state.artists.splice(state.artists.length, 0, artist);
            }
        } else {
            state.artists = [artist];
            saveState("api.artists", [artist]);
        }
    },

    SET_GIG(state, gig) {
        let index = -1;
        if (state.gigs) {
            index = state.gigs.findIndex((v) => v.id === gig.id);
        }

        console.log("set_gig", state.gigs);
        console.log("index of this", index);

        if (index !== -1) {
            Vue.set(state.gigs, index, gig);
            console.log("SET_GIG Vue set", state.gigs);
        } else if (state.gigs) {
            state.gigs.splice(state.gigs.length, 0, gig);
            console.log("SET_GIG splice", state.gigs);
        }
    },

    SET_VENUE(state, venue) {
        let venues = state.venues;
        let index = venues.findIndex((v) => v.id === venue.id);

        if (index !== -1) {
            Vue.set(venues, index, venue);
            saveState("api.venues", state.venues);
        } else {
            state.venues.splice(state.venues.length, 0, venue);
            saveState("api.venues", state.venues);
        }
    },

    SET_VENUE_IDENTITY(state, venueIdentity) {
        let venues = state.venues;
        let venue = venues.find((v) => v.id === venueIdentity.id);

        if (!venue) {
            return;
        }

        venue.name = venueIdentity.name;
        venue.about = venueIdentity.about;
        venue.venue_tags = venueIdentity.venue_tags;
        venue.regions = venueIdentity.regions;
        venue.icon = venueIdentity.icon || venue.icon;
        venue.gallery = venueIdentity.gallery || venue.gallery;

        console.log("SET_VENUE_IDENTITY", venues);
        state.venues = venues;
        saveState("api.venues", state.venues);
    },

    SET_VENUE_CONTACT_DETAILS(state, venueContactDetails) {
        let venues = state.venues;
        let venue = venues.find((v) => v.id === venueContactDetails.id);

        if (venue) {
            venue.email = venueContactDetails.email;
            venue.telephone = venueContactDetails.telephone;
            venue.website = venueContactDetails.website;
            venue.address.address_line_1 =
                venueContactDetails.address.address_line_1;
            venue.address.address_line_2 =
                venueContactDetails.address.address_line_2;
            venue.address.town = venueContactDetails.address.town;
            venue.address.county = venueContactDetails.address.county;
            venue.address.post_code = venueContactDetails.address.post_code;

            console.log("SET_VENUE_CONTACT_DETAILS", venues);
            state.venues = venues;
            saveState("api.venues", state.venues);
        }
    },

    SET_VENUE_BILLING_DETAILS(state, venueBillingDetails) {
        let venues = state.venues;
        let venue = venues.find((v) => v.id === venueBillingDetails.id);
        console.log({ venue, venueBillingDetails });
        if (venue) {
            venue.billing_address.post_code =
                venueBillingDetails.billing_address.post_code;
            venue.billing_address.address_line_1 =
                venueBillingDetails.billing_address.address_line_1;
            venue.billing_address.address_line_2 =
                venueBillingDetails.billing_address.address_line_2;
            venue.billing_address.town =
                venueBillingDetails.billing_address.town;
            venue.billing_address.county =
                venueBillingDetails.billing_address.county;
            venue.billing_address.post_code =
                venueBillingDetails.billing_address.post_code;

            state.venues = venues;
            saveState("api.venues", state.venues);
        }
    },

    // eslint-disable-next-line no-unused-vars
    SET_ARTIST_BIDS(state, artistbids) {
        state.artistbids = artistbids;
        saveState("api.artistbids", artistbids);
    },

    SET_GIG_BIDS(state, gigbids) {
        state.gigbids = gigbids;
        saveState("api.gigbids", gigbids);
    },

    SET_ARTIST_BID(state, artistbid) {
        let index = state.artistbids.findIndex((v) => v.id === artistbid.id);

        if (index !== -1) {
            Vue.set(state.artistbids, index, artistbid);
            console.log("SET_ARTIST_BID Vue set", state.artistbids);
        } else {
            state.artistbids.splice(state.artistbids.length, 0, artistbid);
            console.log("SET_ARTIST_BID splice", state.artistbids);
        }
    },
};

export const getters = {
    domainBrand(state) {
        return state.domainBrand;
    },
    regions(state) {
        return state.regions;
    },
    venueTags(state) {
        return state.venueTags;
    },
    venues(state) {
        return state.venues;
    },
    gigs(state) {
        return state.gigs;
    },
    gigtemplates(state) {
        return state.gigtemplates;
    },
    artisttags(state) {
        return state.artisttags;
    },
    artistbids(state) {
        return state.artistbids;
    },
    uuid(state) {
        return state.uuid;
    },
    gigbids(state) {
        return state.gigbids;
    },
    tiers(state) {
        return state.tiers;
    },
    artists(state) {
        return state.artists;
    },
    artistdirectory(state) {
        return state.artistdirectory;
    },
    faveslists(state) {
        return state.faveslists;
    },
    favouriteList(state) {
        return state.favouriteList;
    },
    hasShowArtistMonthlyInvoices(state) {
        return state.hasShowArtistMonthlyInvoices;
    },
};

export const actions = {
    setShowMonthlyArtistInvoices({ commit }) {
        let showMonthlyInvoices = false;

        if (state.artistbids !== null) {
            state.artistbids.forEach((bid) => {
                if (bid.gig !== undefined && bid.gig !== null) {
                    if (bid.gig.venue !== undefined && bid.gig.venue !== null) {
                        if (
                            bid.gig.venue.brand !== undefined &&
                            bid.gig.venue.brand !== null
                        ) {
                            if (bid.gig.venue.brand.feature_monthly_invoice) {
                                showMonthlyInvoices = true;
                            }
                        }
                    }
                }
            });
        }

        if (showMonthlyInvoices) {
            commit("SHOW_ARTIST_MONTHLY_INVOICES", true);
        }

    },
    loginOpus({commit}){
        return axios.post('/opus-callback', { headers: {
            'Content-Type': 'application/json'
            // explicitly set this for laravel to return a 401 with json object instead of 401 no contents
        }}).then(response => {
            setLoginStates({commit}, response);
            if (response != null){
                return response.data;
            }
        }).catch((error) => {
            return error.response.data;
        });


    },
    authCallback({ commit }, payload) {
        return axios
            .post('/callback', payload)
            .then(response => {
                setLoginStates({commit}, response);
                return response.data
            });
    },

    getPrivateArtistVenues() {
        return axios.get(`/private-venues`);
    },

    getVenueTags({ commit }) {
        return axios.get("/venue-tags").then(function(response) {
            commit("SET_VENUETAGS", response.data.data.venueTags);
            return response.data;
        });
    },

    // eslint-disable-next-line no-empty-pattern
    getUserEventProgress({}) {
        return axios.get("/user/event-progress").then(function(response) {
            return response.data;
        });
    },

    getArtistUnreadMessages({}) {
        return axios.get("/gig/bids/unreadMessages").then(function(response) {
            return response.data;
        });
    },

    getVenueUnreadMessages({}) {
        /*
        return axios
            .get('/unreadMessages').then(function (response){
                return response.data;
            });*/
        return [];
    },

    // eslint-disable-next-line no-empty-pattern
    testBroadcast({}, $payload) {
        axios.post("/dev/testBroadcast", $payload).then(function(response) {
            return response;
        });
    },

    // eslint-disable-next-line no-empty-pattern
    sendMessage({}, $payload) {
        return axios.post("/messages", $payload).then(function(response) {
            return response;
        });
    },
    // eslint-disable-next-line no-empty-pattern
    markAsRead({}, $payload) {
        return axios
            .post("/message/mark-as-read", $payload)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // eslint-disable-next-line no-empty-pattern
    updateSubscription({}, subscription) {
        console.log("subsent", subscription);
        console.log("plan", subscription.plan);
        console.log("paymentMethod", subscription.payMethod);
        axios
            .put("/user/subscription", {
                plan: subscription.plan,
                payment: subscription.payMethod,
            })
            .then(
                function(response) {
                    return response;
                }.bind(this)
            );
    },

    // eslint-disable-next-line no-empty-pattern
    removePaymentMethod({}, paymentID) {
        return axios.post("/user/remove-payment", {
            id: paymentID,
        });
    },

    loadPaymentMethods() {
        return axios.get("/user/payment-methods");
    },

    // eslint-disable-next-line no-empty-pattern
    savePaymentMethod({}, payid) {
        return axios.post("/user/payments", {
            payment_method: payid,
        });
    },
    // eslint-disable-next-line no-unused-vars
    skipArtistRating({ commit }, artists) {
        return axios
            .post(`/ratings/artist-skip`, artists)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    skipAllArtistRatings({ commit }, artists) {
        return axios
            .post(`/ratings/skip-all-artists`, artists)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    submitArtistRating({ commit }, payload) {
        return axios
            .post(`/ratings/artist-submit`, payload)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getTiers({ commit }) {
        console.log("loading tiers");
        return axios
            .get("/tiers?include=genres,moreInfo,vocals,covers")
            .then((response) => {
                const responseTiers = response.data.data;
                commit("SET_TIERS", responseTiers);
                return responseTiers;
            });
    },

    getArtistSignupExcludables() {
        return axios.get("/artist-excludables").then((response) => {
            return response.data;
        });
    },

    getDashboard({}) {
        return axios.get("/dashboard").then((response) => {
            return response.data.data;
        });
    },

    getArtistDirectorySimple({ commit }, venueId) {
        if (state.smallartistdirectory) {
            return state.smallartistdirectory;
        } else {
            let venueParam = "";

            if (venueId) {
                venueParam = `&venueId=${venueId}`;
            }

            return axios
                .get(`/artist-directory-simple?include=tier` + venueParam)
                .then((response) => {
                    const responseDirectory = response.data.data;

                    try {
                        commit("SET_SMALL_ARTIST_DIRECTORY", responseDirectory);
                        console.log("done");
                        return responseDirectory;
                    } catch (ex) {
                        // return response
                        console.log("return anyway");
                        return responseDirectory;
                    }
                });
        }
    },
    async getSuperArtistDirectory({ commit }) {
        if (state.artistdirectory) {
            console.log("using state directory");
            return state.artistdirectory;
        }

        return axios.get("/artist-directory-super").then((response) => {
            const responseDirectory = response.data.data;
            try {
                commit("SET_ARTIST_DIRECTORY", responseDirectory);
                console.log("done");
                return responseDirectory;
            } catch (ex) {
                // return response
                console.log("return anyway");
                return responseDirectory;
            }
        });
    },
    async getArtistDirectory({ commit }) {
        if (state.artistdirectory) {
            console.log("using state directory");
            return state.artistdirectory;
        }

        return axios.get("/artist-directory").then((response) => {
            const responseDirectory = response.data.data;
            try {
                commit("SET_ARTIST_DIRECTORY", responseDirectory);
                console.log("done");
                return responseDirectory;
            } catch (ex) {
                // return response
                console.log("return anyway");
                return responseDirectory;
            }
        });
    },
    loadStripeIntent() {
        return axios.get("/user/setup-intent");
    },
    getArtistBid({}, id) {
        return axios
            .get(`/artist/bids/${id}`)
            .then((response) => {
                const responseBid = response.data.data;
                console.log("response bid", responseBid);
                this.item = responseBid;
                //   commit('SET_ARTIST_BID', responseBid)
                return responseBid;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    signoffArtists({}, ids) {
        return axios
            .post("/gig/bids/signoff", { bids: ids })
            .then(() => true)
            .catch((err) => {
                console.log(err);
                return false;
            });
    },
    getArtistInvoices({}) {
        return axios.get("/artist/invoices?include=artist,gig.venue,gig");
    },
    getArtistBids({ commit }) {
        return axios
            .get("/artist/bids")
            .then((response) => {
                const bids = response.data.data;

                commit("SET_ARTIST_BIDS", bids);
                return bids;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getUserNotificationsCount({}, userId) {
        return axios
            .get(`/user/notifications/count/${userId}`)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getGigLocations() {
        return axios
            .get("locations")
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getGigCancellationReasons({ commit }, types = false){
        console.log("commit", commit)
        let queryString = '';

        if (types !== false) {
            queryString = types.map(type => `types[]=${encodeURIComponent(type)}`).join('&');
        }

        return axios.get(`/gigs/cancellation-reasons?${queryString}`)
    },
    requestBookingCancellation({ commit },params) {
        console.log("commit", commit)
        return axios
            .put(`/bids/request-cancellation/${params.bidId}`, {reason_id: params.reason_id, other: params.other})
            .then(response => {
                return response
            })
            .catch(err => {
                console.log("cancel bid error", err)
            })
    },
    getGigBids({ commit }) {
        return axios
            .get("/gig/bids")
            .then((response) => {
                const bids = response.data.data;

                commit("SET_GIG_BIDS", bids);
                return bids;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    acceptFinal({ commit }, bid) {
        return axios
            .post(`/gig/bids/sendFinalConfirmation`, bid)
            .then((response) => {
                const updatedBid = response.data.data;
                commit("SET_ARTIST_BID", updatedBid);
                return updatedBid;
            })
            .catch((err) => {
                console.log("update bid error", err);
            });
    },
    updateArtistBid({ commit }, bid) {
        console.log("bid", bid);
        return axios
            .put(`/artist/bids/${bid.id}`, bid)
            .then((response) => {
                const updatedBid = response.data.data;
                console.log("gig", updatedBid);
                commit("SET_ARTIST_BID", updatedBid);
                return updatedBid;
            })
            .catch((err) => {
                console.log("update bid error", err);
            });
    },
    multiInitialArtistDecline({ commit }, bids) {
        return axios
            .put(`/artist/bids/set_multi_decline_initial_offer`, {
                bids: bids,
            })
            .then((response) => {
                const updatedBid = response.data.data;
                console.log("gig", updatedBid);
                commit("SET_ARTIST_BID", updatedBid);
                return updatedBid;
            })
            .catch((err) => {
                console.log("update bid error", err);
            });
    },
    declineArtistFinal({ commit }, bid) {
        return axios
            .put(`/artist/bids/set_decline_final_offer`, bid)
            .then((response) => {
                const updatedBid = response.data.data;
                commit("SET_ARTIST_BID", updatedBid);
                return updatedBid;
            })
            .catch((err) => {
                console.log("update bid error", err);
            });
    },
    initialArtistAcceptance({ commit }, bid) {
        return axios
            .put(`/artist/bids/set_accept_initial_offer`, bid)
            .then((response) => {
                const updatedBid = response.data.data;
                console.log("gig here", updatedBid);
                commit("SET_ARTIST_BID", updatedBid);
                return updatedBid;
            })
            .catch((err) => {
                console.log("update bid error", err);
            });
    },
    multiInitialArtistAcceptance({ commit }, bids) {
        return axios
            .put(`/artist/bids/set_multi_accept_initial_offer`, {
                bids: bids,
            })
            .then((response) => {
                const updatedBid = response.data.data;
                console.log("gig here", updatedBid);
                commit("SET_ARTIST_BID", updatedBid);
                return updatedBid;
            })
            .catch((err) => {
                console.log("update bid error", err);
            });
    },

    // eslint-disable-next-line no-unused-vars
    updateGigBid({ commit }, bid) {
        console.log("bid", bid);
        return axios
            .put(`/gig/bids/${bid.id}`, bid)
            .then((resp) => resp.data.data)
            .catch((err) => {
                console.log("update bid error", err);
            });
    },

    // eslint-disable-next-line no-unused-vars
    acceptAvailability({ commit }, bid) {
        console.log("bid", bid);
        return axios
            .put(`/gig/bids/accept_availability`, bid)
            .then((resp) => resp.data.data);
    },
    // eslint-disable-next-line no-unused-vars
    declineAvailability({ commit }, bid) {
        console.log("bid", bid);
        return axios
            .put(`/gig/bids/decline_availability`, bid)
            .then((resp) => resp.data.data)
            .catch((err) => {
                console.log("update bid error", err);
            });
    },

    getGig({ commit }, id) {
        console.log("getting id", id);

        return axios
            .get(`/gigs/` + id + "?include=bookings,venue")
            .then((response) => {
                const responseGig = response.data.data;
                console.log("response gig", responseGig);
                this.item = responseGig;
                commit("SET_GIG", responseGig);
                return responseGig;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getGigs({ commit }) {
        return axios
            .get("/gigs")
            .then((response) => {
                const gigs = response.data.data;

                commit("SET_GIGS", gigs);
                return gigs;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getGigsMessages() {
        return axios
            .get("/gigs")
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getGigMessages({}, id) {
        return axios
            .get(
                `/gigs/` + id + "?include=venue,bids,bids.messages,bids.artist"
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getGigList({}, $range) {
        return axios
            .get(
                "/gig-list?filter[starts_between]=" +
                    $range.from +
                    "," +
                    $range.to +
                    "&include=venue,bookings"
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
        // eslint-disable-next-line no-unused-vars
    async getGigWithOffers({commit}, params) {

        const argParams = params.queryParams !== undefined && params.queryParams.length > 0
            ? params.queryParams.join()
            : 'bids,gigbids.artist,bids.artist,bids.artist.specialities,bids.artist.specialities.moreInfo,' +
            'bookings,venue,bookings.invoice,venue.address,requestedCancellationBookings'

        const queryParams = new URLSearchParams({
            include: argParams,
        });

        if (
            params.useTrashedArtists !== undefined &&
            params.useTrashedArtists === true
        ) {
            queryParams.append("include_deleted_artists", "true");
        }

        try {
            const response = await axios.get(
                `/gigs/${params.id}?${queryParams.toString()}`
            );
            return response.data.data;
        } catch (err) {
            console.error(err);
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getGigDetailsWithOffers({ commit }, params) {
        const argParams =
            params.queryParams !== undefined && params.queryParams.length > 0
                ? params.queryParams.join()
                : "gigTiers,bookings,venue,bids";

        const queryParams = new URLSearchParams({
            include: argParams,
        });

        if (
            params.useTrashedArtists !== undefined &&
            params.useTrashedArtists === true
        ) {
            queryParams.append("include_deleted_artists", "true");
        }

        try {
            const response = await axios.get(
                `/gig-details/${params.id}?${queryParams.toString()}`
            );
            return response.data.data;
        } catch (err) {
            console.error(err);
        }
    },
    // eslint-disable-next-line no-unused-vars
    async getGigBidsWithOffers({commit}, params) {

        const argParams = params.queryParams.includes !== undefined && params.queryParams.length > 0
            ? params.queryParams.join()
            : 'gigTiers,bookings,venue,bids,requestedCancellationBookings'

        let queryParams = {
            page: params.page,
            include: argParams,
        };

        if (params.artistTagId !== null) {
            queryParams.artistTagId = params.artistTagId;
        }

        if (
            params.useTrashedArtists !== undefined &&
            params.useTrashedArtists === true
        ) {
            queryParams.include_deleted_artists = true;
        }

        try {
            const response = await axios.get(`/gig-bids/${params.id}`, {
                params: queryParams,
            });
            return response.data.data;
        } catch (err) {
            console.error(err);
        }
    },

    getGigDetails({}, id) {
        return axios
            .get(
                "/gigs/" +
                    id +
                    "?include=bids,stagedArtists,artistTags,gigTiers,gigSearchTiers,gigTiers,bids,bookings,venue"
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getGigChosenArtists({}, params) {
        const paramArgs =
            params.queryParams !== undefined && params.queryParams.length > 0
                ? params.queryParams
                : [
                      "bids",
                      "stagedArtists",
                      "artistTags",
                      "gigTiers",
                      "gigSearchTiers",
                      "searchTiers",
                      "gigTiers",
                      "bids",
                      "bookings",
                      "stagedArtists.artist",
                      "stagedArtists.artist.artistTags",
                      "venue",
                      "venue.user",
                      "venue.activeSubscription",
                      "venue.activeSubscription.product",
                  ];

        return axios
            .get(`/gigs/${params.id}`, {
                params: {
                    include: paramArgs.join(","),
                    append: "remaining_usage",
                },
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getGigWithNotes({}, id) {
        return axios
            .get(`/gig-notes/${id}?include=notes,bookings,venue`)
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // eslint-disable-next-line no-unused-vars
    getGigsByRange({ commit }, $range) {
        return axios
            .get(
                "/gigs?filter[starts_between]=" +
                    $range.from +
                    "," +
                    $range.to +
                    "&include=venue,bookings"
            )
            .then((response) => {
                let gigs = response.data.data;
                return gigs;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getFutureGigs({}) {
        let $now = DateTime.now().toFormat("yyyy-MM-dd");

        return axios
            .get("/gigs?filter[starts_between]=" + $now + ",2030-01-01")
            .then((response) => {
                let gigs = response.data.data;
                return gigs;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // eslint-disable-next-line no-unused-vars
    getArtistWithSpecialities({ commit }, id) {
        return axios
            .get(
                `/artists/${id}/specialities?include=genres,vocals,moreInfo,covers`
            )
            .then((response) => {
                const artist = response.data.data;
                //commit('SET_ARTIST', artist)
                return artist;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // eslint-disable-next-line no-unused-vars
    getArtist({ commit }, id) {
        return axios
            .get(
                `/artist-directory/${id}?include=specialities,specialities.genres`
            )
            .then((response) => {
                const artist = response.data.data;
                //commit('SET_ARTIST', artist)
                return artist;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getArtists({ commit }) {
        return axios.get("/artists").then((response) => {
            const artists = response.data.data;
            console.log("get artists", artists);
            commit("SET_ARTISTS", artists);
            return artists;
        });
    },
    claimProfile({ commit }, id) {
        return axios
            .post(`/claim/${id}`)
            .then((response) => {
                const artist = response.data.data;
                console.log("claim artist", artist);
                commit("SET_ARTIST", artist);
                return response;
            })
            .catch((err) => {
                return err;
            });
    },
    createArtist({ commit }, artist) {
        return axios
            .post("/artists", artist)
            .then((response) => {
                const artist = response.data.data;
                commit("SET_ARTIST", artist);
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    },

    // eslint-disable-next-line no-unused-vars
    updateArtist({ commit }, { artistId, artist }) {
        return axios
            .put(`/artists/${artistId}`, artist)
            .then((response) => {
                console.log(response.data.data);
                //const updatedArtist = response.data.data;
                //commit('SET_ARTIST', updatedArtist);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response.data;
            });
    },

    createGig({ commit }, gig) {
        console.log(gig);
        return axios
            .post("/gigs", gig)
            .then((response) => {
                const createdGig = response.data.data;
                console.log("gig", createdGig);
                commit("SET_GIG", createdGig);
                return createdGig;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    updateGig({ commit }, gig) {
        console.log(gig);
        return axios.put(`/gigs/${gig.id}`, gig).then((response) => {
            const updatedGig = response.data.data;
            console.log("gig", updatedGig);
            commit("SET_GIG", updatedGig);
            return updatedGig;
        });
    },

    getGigTemplates({ commit }) {
        return axios
            .get("/gigtemplates")
            .then((response) => {
                const gigtemplates = response.data.data;
                commit("SET_GIG_TEMPLATES", gigtemplates);
                return gigtemplates;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    deleteGigTemplate({ commit }, gigtemplate) {
        return axios.delete(`/gigtemplates/${gigtemplate.id}`, gigtemplate);
    },
    getPastGigs({}) {
        return axios
            .get("/past-gigs?include=gig.venue,gig,artist,invoice")
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    createGigTemplate({ commit }, gigtemplate) {
        console.log(gigtemplate);
        return axios
            .post("/gigtemplates", gigtemplate)
            .then((response) => {
                const createdGigtemplate = response.data.data;
                console.log("gigtemplate", createdGigtemplate);
                commit("SET_GIG_TEMPLATES", createdGigtemplate);
                return createdGigtemplate;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    updateGigTemplate({ commit }, gigtemplate) {
        console.log("updateGigTemplate", gigtemplate);
        return axios
            .put(`/gigtemplates/${gigtemplate.id}`, gigtemplate)
            .then((response) => {
                const updatedGigtemplate = response.data.data;
                console.log("saved gig template", updatedGigtemplate);
                commit("SET_GIG_TEMPLATE", updatedGigtemplate);
                return updatedGigtemplate;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    applyGigTemplate({ commit }, payload) {
        return axios.post(`/gigtemplates/apply`, payload).then((response) => {
            const newGigID = response.data.data;
            return newGigID;
        });
    },
    // eslint-disable-next-line no-unused-vars
    createGigRepeats({ commit }, payload) {
        return axios.put("/gig/repeat", payload);
    },

    markInvoiceAsPaid({}, $bidId) {
        return axios.post("/gig/bids/mark-as-paid", $bidId).then(() => {
            this.getGigs();
        });
    },

    getArtistTags({ commit }) {
        if (state.artisttags) {
            return state.artisttags;
        }

        console.log("loading artist tags");
        return axios
            .get("/artisttags")
            .then((response) => {
                const artisttags = response.data.data;
                console.log("gigtags", artisttags);
                commit("SET_ARTISTTAGS", artisttags);
                return artisttags;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-empty-pattern
    // eslint-disable-next-line no-unused-vars
    createNote({ commit }, note) {
        console.log(note);
        return axios
            .post(`/gig/notes`, note)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    updateNote({ commit }, note) {
        console.log("save note", note);
        return axios
            .put(`/gig/notes/${note.id}`, note)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    deleteNote({ commit }, note) {
        return axios
            .delete(`/gig/notes/${note.id}`, note)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    deleteGig({ commit }, gig) {
        return axios
            .delete(`/gigs/${gig.id}`, gig)
            .then((response) => {
                const note = response.data.data;
                return note;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getPendingGigs({}, params) {
        let paramsObj = {
            page: params.page,
            include: params.include,
            statuses: params.statuses,
            venueIds: params.venue_ids,
            custom_tags: params.custom_tags,
        };

        const queryParams = new URLSearchParams(paramsObj);

        return axios
            .get(
                `/gigs-filter?filter[starts_between]=${
                    params.starts_between
                }&${queryParams.toString()}`
            )
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-empty-pattern
    getVenue({}, id) {
        console.log("getVenue >>", id);
        return axios
            .get(`/venues/${id}`)
            .then((response) => {
                const venue = response.data.data;
                console.log("venue", venue);
                return venue;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getRolePermissions({}) {
        return axios
            .get("permissions")
            .then((response) => {
                console.log(response.data);
                return true;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-empty-pattern
    getVenueRoles({}, id) {
        console.log("getVenueRoles >>", id);
        return axios
            .get(`/venues/${id}/roles`)
            .then((response) => {
                console.log(response.data.data);
                const venue = response.data.data;
                console.log("venue", venue);
                return venue;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getVenues({ commit }) {
        console.log("loading venues");
        return axios
            .get("/venues")
            .then((response) => {
                const venues = response.data.data;
                console.log("venues", venues);
                commit("SET_VENUES", venues);
                return venues;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    async getDomainBrand({ commit }, domain) {
        const response = await fetchDomainBrand(domain);

        let brand = null;

        if (response.data !== undefined) {
            if (response.data.data !== undefined) {
                brand = response.data.data;
            }
        }

        commit("SET_DOMAIN_BRAND", brand);
        return brand;
    },
    createVenue({ commit }, venue) {
        return axios
            .post("/venues", venue, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const venue = response.data.data;
                console.log("venue", venue);
                commit("SET_VENUE", venue);
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    updateVenue({ commit }, { formData }) {
        return axios
            .post(`/venues/${formData.get("id")}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const updateVenueResponse = response;
                console.log("venue", updateVenueResponse.data.data);
                commit("SET_VENUE", updateVenueResponse.data.data);
                return updateVenueResponse;
            });
    },

    setVenueIdentity({ commit }, venueIdentity) {
        commit("SET_VENUE_IDENTITY", venueIdentity);
    },

    setVenueContactDetails({ commit }, venueContactDetails) {
        commit("SET_VENUE_CONTACT_DETAILS", venueContactDetails);
    },

    setVenueBillingDetails({ commit }, venueBillingDetails) {
        commit("SET_VENUE_BILLING_DETAILS", venueBillingDetails);
    },

    getFavesLists({}) {
        console.log("getting faves list");
        return axios.get("/favourites-lists/").then((response) => {
            return response.data.data;
        });
    },
    getFavouriteList({}, favouriteListId) {
        return axios
            .get(`/favourites-lists/${favouriteListId}`)
            .then((response) => {
                return response.data.data;
            });
    },
    // eslint-disable-next-line no-unused-vars
    createFavesList({}, favelist) {
        return axios.post("/favourites-lists", favelist).then((resp) => {
            return resp.data.data;
        });
    },
    updateFavesList({}, $list) {
        return axios
            .patch(`/favourites-lists/${$list.id}`, $list)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    deleteFavesListArtist({}, removal) {
        console.log("inner remove", removal, removal);
        return axios
            .delete(
                `/favourites-lists/${removal.favelist}/artists/${removal.favelistartist}`
            )
            .then((response) => {
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    // eslint-disable-next-line no-unused-vars
    deleteArtistProfile({ commit }, id) {
        return axios.delete(`/artists/${id}`);
    },
    // eslint-disable-next-line no-unused-vars
    deleteFavesList({}, faveslist) {
        return axios
            .delete(`/favourites-lists/${faveslist.id}`, faveslist)
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getPrivateVenueRequests({}, userId) {
        return axios.get(`/private-venues-artists/${userId}`);
    },
    getPrivateVenueRequestsByArtist({}, artistId) {
        return axios.get(`/artists/private-venues-artists/${artistId}`);
    },
    saveVenuePrivateArtists({}, payload) {
        return axios.post(`/private-venues-artists`, payload);
    },
    saveVenuePrivateArtist({}, payload) {
        return axios.put(`/private-venues-artists/${payload.id}`, payload);
    },

    createFave({}, payload) {
        let finalPayload = {
            artist: payload.artist_id,
            faveLists: payload.faveLists,
        };
        if (payload.private_venue_artists !== undefined) {
            finalPayload.private_venue_artists = payload.private_venue_artists;
        }

        return axios
            .post(
                `/favourites-lists/${payload.artist_id}/artists`,
                finalPayload
            )
            .then((response) => {
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    deleteFave({}, list_id, artist_id) {
        return axios
            .delete(`/faveslists/${list_id}/{${artist_id}}`)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getFilteredInvoices({}, params) {
        const queryParams = new URLSearchParams({
            page: params.request.page,
            include:
                "artist,gig.venue,gig.customTags,gig.venue.billing_address,artist.billing_address,signedOffBy",
        });

        if (
            params.useTrashedArtists !== undefined &&
            params.useTrashedArtists === true
        ) {
            queryParams.append("include_deleted_artists", "true");
        }

        return axios
            .post(`/invoices?${queryParams.toString()}`, params.request)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getInvoice({}, invoiceId) {

        return axios
            .get(`/invoices/${invoiceId}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    updateArtistAccountCode({}, params) {
        return axios
            .put(`/artist/artist-account-code/${params.artistId}`, {
                brandId: params.brandId,
                accountCode: params.accountCode,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getFilteredMonthlyInvoices({}, params) {
        const queryParams = new URLSearchParams({
            page: params.request.page,
            include:
                "artist,gig.venue,gig.venue.billing_address,artist.billing_address,signedOffBy",
            isArtist: params.isArtist,
        });

        if (
            params.useTrashedArtists !== undefined &&
            params.useTrashedArtists === true
        ) {
            queryParams.append("include_deleted_artists", "true");
        }

        return axios
            .post(`/monthly-invoices?${queryParams.toString()}`, params.request)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    getInvoices({}, request) {
        let $filters = "";
        if (request.venue_id) {
            $filters += "&venue_id=" + request.venue_id;
        }

        if (request.date_from) {
            $filters += "&date_from=" + request.date_from;
        }

        if (request.date_to) {
            $filters += "&date_to=" + request.date_to;
        }

        if (request.invoice_number) {
            $filters += "&invoice_number=" + request.invoice_number;
        }

        if (request.invoice_sortby) {
            $filters += "&invoice_sortby=" + request.invoice_sortby;
        }

        return axios
            .get(
                `/invoices?page=${request.page}&include=artist,gig.venue,gig.venue.billing_address,artist.billing_address,signedOffBy&excludeArchived=${request.excludeArchived}${$filters}`
            )
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    archiveInvoice({}, bid) {
        return axios.post(`invoices/${bid.id}/archives`);
    },
    archiveAllSelected({}, bids) {
        return axios.post(`invoices/archiveallselected`, { bids: bids });
    },

    archiveAllSelectedMonthly({}, invoiceIds) {
        return axios.post(`monthly-invoices/archiveallselected`, {
            invoiceIds: invoiceIds,
        });
    },

    // eslint-disable-next-line no-unused-vars
    bookingExport({ commit }, payload) {
        return axios({
            responseType: "blob",
            url: "/invoices/export",
            method: "POST",
            data: { booking_ids: payload },
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    },
    bookingExportMonthly({}, payload) {
        return axios({
            responseType: "blob",
            url: "/monthly-invoices/export",
            method: "POST",
            data: { monthly_invoice_params: payload },
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getMonthlyInvoice({}, params) {
        return axios
            .get(`/monthly-invoice-month`, {
                params: params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getMonthlyInvoices({}, params) {
        return axios
            .get(`/monthly-invoices-month`, {
                params: params,
            })
            .then((response) => {
                return response.data;
            })
            .catch(err => {
                console.log(err)
            })
    },

    /**------------- ANALYTICS -------------**/
    getBasicAnalytics({}, params){
        return axios.get('/analytics', {params: params})
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log(err)
            });
    }
}

// ===
// Private helpers
// ===

function getSavedState(key) {
    if (largeDataSets[key]) {
        return Session().get(key);
    } else {
        return JSON.parse(window.sessionStorage.getItem(key));
    }
}

function saveState(key, state) {
    if (largeDataSets[key] && largeDataSets[key] != undefined) {
        return Session().set(key, state);
    } else if (state) {
        window.sessionStorage.setItem(key, JSON.stringify(state));
    }
}

async function fetchDomainBrand(domain) {
    let response;

    await axios
        .get(`/domain-brand?domain=${btoa(encodeURIComponent(domain))}`)
        .then((res) => {
            response = res;
        })
        .catch((res) => {
            console.error("fetchDomainBrand Error", res);
        });

    return response;
}
