<template>
    <div>
        <div v-if="loadingGigs" class="overlay"></div>
        <CalendarWrapper
            calendar-type="Gigs"
            :view-options="calendarViewOptions"
            :available-status-keys="calendarAvailableStatusKeys"
            :schedules="schedules"
            :selectedVenue="filteredVenues"
            :weekStartDay="toggleWeekStartDay"
            @selected="scheduleSelected" 
            @refresh="onCalendarRefresh"         
            @create="onCalendarCreate"      
            @update="onCalendarUpdated"                
            id="calendarWrapper"
            ref="calendarwrapper"
            v-on:statusFiltered="filterByStatus($event)"
            v-on:tagFiltered="filterByTags($event)"
        >
            <template v-slot:filters>
                <!-- User Venue filter -->
                <TRichSelect
                    id="venue-selection"
                    ref="venuepicker"
                    name="venue-selection"
                    placeholder="Filter by venue"
                    :options="venues"
                    value-attribute="id"
                    text-attribute="name"
                    class="w-full"
                    @blur="fetchAndProcessGigs"
                    v-model="filteredVenues"
                />
            </template>

            <template v-slot:additionalFilters>
                <div
                    class="flex items-center justify-end pt-1 pb-4 col-span-full"
                >
                    <span
                        class="mr-4 text-sm md:text-md"
                        v-if="toggleArtistsNames"
                        >Showing artist name</span
                    >
                    <span class="mr-4 text-sm md:text-md" v-else
                        >Showing gig name</span
                    >
                    <SwitchToggle
                        :enabled="toggleArtistsNames"
                        @updateEnable="onToggleArtistsNames"
                    />
                </div>
            </template>
            <template v-slot:weekStartDay>
                <div
                    class="flex items-center justify-end pt-1 pb-4 col-span-full"
                >
                    <span
                        class="mr-4 text-sm md:text-md"
                        v-if="toggleWeekStartDay"
                        >Start week on Monday</span
                    >
                    <span class="mr-4 text-sm md:text-md" v-else
                        >Start week on Sunday</span
                    >
                    <SwitchToggle
                        :enabled="toggleWeekStartDay"
                        @updateEnable="onToggleWeekStartDay"
                    />
                </div>
            </template>
        </CalendarWrapper>
        <!-- Schedule modal -->
        <calendar-preview
            v-if="filteredVenues !== null"
            ref="calendarPreview"
            @refresh="fetchAndProcessGigs()"
            :view-type="'VENUE'"
            :bid-id="selectedBidId"
            :show-as-bid="false"
        />

        <CalendarHover ref="calendarhover" id="tooltip" />
    </div>
</template>

<script>
import NProgress from "nprogress";
import { mapState } from "vuex";
import { TRichSelect } from "vue-tailwind/dist/components";
import CalendarWrapper from "@/components/calendar/CalendarWrapper.vue";
import CalendarPreview from "@/components/modal/CalendarPreview.vue";
import CalendarHover from "@/components/modal/CalendarHover.vue";
import SwitchToggle from "@/components/ui/form/SwitchToggle.vue";
import {
    getStatusesFromTypes,
    getStatusFromType,
    hasGigBeenBooked,
} from "@/state/models/gigStatus";
import normalizeDate from "@/utils/normalizeDate";
import { venueMethods } from "@/state/helpers";
const { DateTime } = require("luxon");
import client from "../../utils/client";

const VENUE_LOCK_KEY = "LOCK_VENUE_1";
const ARTIST_TOGGLE_KEY = "SHOW_ARTIST_NAME";

export default {
    components: {
        TRichSelect,
        CalendarWrapper,
        CalendarPreview,
        CalendarHover,
        SwitchToggle,
    },
    data() {
        return {
            mobile: false,
            gigs: null,
            venues: null,
            schedules: [],
            tooltip: false,
            gigsParsed: false,
            filteredGigs: [],
            filteredVenues: [],
            isLoading: true,
            selectedBidId: null,
            calendarViewOptions: [
                { value: "month", text: "Month view" },
                { value: "week", text: "Week view" },
                { value: "day", text: "Day view" },
            ],
            calendarAvailableStatusKeys: getStatusesFromTypes([
                "UNPUBLISHED",
                "CANCELLED",
                "NEEDS_RESPONSE",
                "PENDING",
                "CONFIRMED",
                "NEEDS_SIGNOFF",
                "SIGNED_OFF",
                'CANCELLATION_REQUESTED_BY_ARTIST',
            ]),
            calendarParams: {
                currentDate: null,
                endDateRange: null,
                startDateRange: null,
                view: null,
            },
            toggleArtistsNames: false,
            toggleWeekStartDay: false,
            loadingGigs: true,
            statusFilters: [
                "UNPUBLISHED",
                "CANCELLED",
                "NEEDS_RESPONSE",
                "PENDING",
                "CONFIRMED",
                "NEEDS_SIGNOFF",
                "SIGNED_OFF",
                'CANCELLATION_REQUESTED_BY_ARTIST',
            ],
            tagFilters: [],
            isMounted: false,
        };
    },
    computed: {
        bankHolidays() {
            return this.$store.getters["bankHolidays/bankHolidays"];
        },
        ...mapState({
            calendarStartDay: (state) => state.calendarStartDay,
        }),
    },
    watch: { 
        filteredVenues() {
            if (this.isMounted){
                if (this.filteredVenues === "0") {
                    this.$refs.calendarwrapper.allVenueFilterSelected();
                } else if (this.filteredVenues === null) {
                    this.getVenueCalendarList();
                } else {
                    this.$refs.calendarwrapper.selectVenueFilterSelected();
                }
                localStorage.setItem(VENUE_LOCK_KEY, this.filteredVenues);
                this.fetchAndProcessGigs();
            }
        },
    },
    methods: {
        ...venueMethods,
        fetchAndProcessGigs() {
            //if (this.filteredVenues === null) {
            //    this.setKeysFromLocalStorage();
            //}
            this.loadingGigs = true;
            this.gigs = null;
            this.filteredGigs = [];
            this.getVenueCalendarList();
            //this.setVenue(this.filteredVenues);
            let calendarInstance = this.$refs.calendarwrapper; 
            let range = {
                from: DateTime.fromJSDate(
                    calendarInstance.startDateRange
                ).toFormat("yyyy-MM-dd"),
                to: DateTime.fromJSDate(calendarInstance.endDateRange)
                    .plus({ days: 1 })
                    .toFormat("yyyy-MM-dd"),
            };
            //this.setKeysFromLocalStorage();
            if (this.filteredVenues != "0") {
                range.venue_id = this.filteredVenues;
            }
            range.statuses = this.statusFilters;
            range.tags = this.tagFilters;
            NProgress.start();
            //if(range.venue_id){
                this.$store
                    .dispatch("gig/getGigsCalendarByRange", range)
                    .then((response) => {
                        this.gigs = JSON.parse(JSON.stringify(response));

                        const availableStatusKeysSet = new Set(
                            this.calendarAvailableStatusKeys.map((i) => i.type)
                        );

                        this.filteredGigs = this.gigs.reduce((acc, gig) => {
                            if (
                                gig.status &&
                                availableStatusKeysSet.has(gig.status)
                            ) {
                                acc.push(this.parseGigToSchedule(gig));
                            }
                            return acc;
                        }, []);
                    })
                    .finally(() => {
                        this.setCalendarItems();
                    })
                    .catch((err) => {
                        console.log(err);
                        NProgress.done();
                        this.loadingGigs = false;
                        this.isLoading = false; // Stop loading animation in case of error
                    });
               // }

        },

        filterByStatus(event) {
            if (event.length) {
                
                this.statusFilters = event;
                this.fetchAndProcessGigs();
            } else {
                this.gigs = null;
                this.filteredGigs = [];
                this.setCalendarItems();
            }
        },
        filterByTags(event) {
            this.tagFilters = event; 
            this.fetchAndProcessGigs();
        },
        parseGigToSchedule(gig) {
            const status = getStatusFromType(gig.status);
            const time = DateTime.fromISO(gig.start)
                .toUTC()
                .toFormat("t")
                .replaceAll(" ", "")
                .toLowerCase();
            // Title
            let scheduleTitle = !this.toggleArtistsNames
                ? gig.name
                : hasGigBeenBooked(gig.status)
                ? gig.bookings.length && gig.bookings[0].artist !== null
                    ? gig.bookings[0].artist.name
                    : "-"
                : status.name;

            let cancellationReason = null
            if (gig.cancellation_reason && gig.cancellation_reason.display_in_calendar) {
              scheduleTitle = `${scheduleTitle} - ${gig.cancellation_reason.reason}`
            }
            if (gig.cancellation_reason && gig.cancellation_reason.id === 3) {
              gig.status = 'CANCELLATION_REQUESTED_BY_ARTIST'
            }

            // End date/time
            let end = gig.end;

            let startHour = DateTime.fromISO(gig.start).toUTC().hour;
            let endHour = DateTime.fromISO(gig.end).toUTC().hour;

            if (endHour < startHour) {
                end = DateTime.fromISO(gig.start)
                    .toUTC()
                    .set({ hours: 23, minutes: 59 });
                // prevent calendar going across two days
            }

            return {
                id: gig.id,
                title: time + " " + scheduleTitle,
                category: "time",
                start: normalizeDate(gig.start),
                end: normalizeDate(end),
                calendarId: 0,
                raw: gig,
                bgColor: status.bg,
                color: status.color,
                customStyle: "background-color:" + status.bg,
                cancellationReason: cancellationReason
            };
        },
        setCalendarItems() {
            this.schedules = [];
            const schedules =
                this.filteredVenues && this.filteredVenues !== "0"
                    ? this.filteredGigs.filter(
                          (s) =>
                              String(s.raw.venue.id) ===
                              String(this.filteredVenues)
                      )
                    : [...this.filteredGigs];

            const bankHolidays = this.bankHolidays.map((bankHoliday) => ({
                title:
                    bankHoliday.region === "England and Wales"
                        ? bankHoliday.title
                        : `${bankHoliday.title} (${bankHoliday.region})`,
                category: "time",
                start: bankHoliday.date,
                calendarId: 0,
                bgColor: "#E0BBE4",
                color: "#E0BBE4",
                customStyle: "background-color: #E0BBE4",
            }));

            this.schedules = [...schedules, ...bankHolidays];
            NProgress.done();
            this.loadingGigs = false;
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },

        scheduleSelected(schedule) {
            if (!this.$store.getters["user/hasManageGigsPermission"]) {
                return null;
            }
            let preview = this.$refs.calendarPreview;
            preview.item = schedule.raw;
            this.$refs.calendarhover.tooltip = false;
            preview.viewModal = true;
        },
        onCalendarCreate(event){
            if (this.$store.getters["user/hasManageGigsPermission"]) {
                this.$router.push({
                    path: "/gigs/add",
                    query: {
                        start: event.start._date.toISOString(),
                        end: event.end._date.toISOString(),
                    },
                });
            }
        },
        onCalendarUpdated() {
            if(this.filteredVenues !== null) {
                this.fetchAndProcessGigs();
            }
        },
        onCalendarRefresh() { 
            this.fetchAndProcessGigsGigs();
        },
        onToggleArtistsNames() { 
            this.toggleArtistsNames = !this.toggleArtistsNames;
            this.fetchAndProcessGigs();
            localStorage.setItem(ARTIST_TOGGLE_KEY, this.toggleArtistsNames);
        },

        onToggleWeekStartDay() {
            this.toggleWeekStartDay = !this.toggleWeekStartDay;
            this.$store.dispatch(
                "setCalendarStartDay",
                this.toggleWeekStartDay
            );
            this.setWeekStartDay();
        },

        setWeekStartDay() {
            client.put("user/update-calendar-start/", {
                state: this.toggleWeekStartDay,
            }).then(() => {
                this.$emit("weekStartDayChanged", this.toggleWeekStartDay);
            });
        },

        setKeysFromLocalStorage() {
            let venueKeyValue = localStorage.getItem(VENUE_LOCK_KEY);
            if (localStorage.getItem(VENUE_LOCK_KEY)) {
                this.filteredVenues = Number(venueKeyValue);
            }
            let artistKey = localStorage.getItem(ARTIST_TOGGLE_KEY);
            if (artistKey) {
                this.toggleArtistsNames = Boolean(artistKey);
            }
        },
        validateLocalStorageFilterValues() {
            let storedVenueFilter = localStorage.getItem(VENUE_LOCK_KEY);

            if (storedVenueFilter !== null && storedVenueFilter !== "0") {
                if (
                    this.venues
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(parseInt(storedVenueFilter)) === -1
                ) {
                    localStorage.removeItem(VENUE_LOCK_KEY);
                }
            }
        },
        getVenueCalendarList(){
            this.getVenuesCalendarList()
            .then((response) => {
                this.venues = [{ name: "All venues", id: 0 }, ...response];
                //this.filteredVenues = this.venues[1].id;
                //this.validateLocalStorageFilterValues();
                this.setKeysFromLocalStorage(); 
            })
            .catch((err) => {
                console.log(err);
                this.isLoading = false;
                this.loadingGigs = false;
                if (!this.loadingGigs) {
                    NProgress.done();
                }
            });
        },
    },
    async mounted() {
        this.getVenueCalendarList();
        this.setKeysFromLocalStorage();
        this.$nextTick(() => {
            if (this.$refs.calendarhover) {
                this.$refs.calendarhover.isArtistView = false;
            }
        });
        this.toggleWeekStartDay = !!this.calendarStartDay;

        NProgress.start();

        this.$store.dispatch("bankHolidays/getBankHolidays");
        var query = window.matchMedia("(max-width:768px)");

        if (query.matches) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }

        if (!this.mobile) {
            const tooltip = document.querySelector("#tooltip");

            const calendarWrapper = document.getElementById("calendarWrapper");
            calendarWrapper.addEventListener("mouseover", (e) => {
                const el = e.target;
                const schedule = el.closest(
                    ".tui-full-calendar-weekday-schedule"
                );

                if (schedule) {
                    let scheduleID = schedule.getAttribute("data-schedule-id");
                    let gigObj = this.gigs.find((gig) => gig.id == scheduleID);

                    this.$refs.calendarhover.isArtistView = false;
                    this.$refs.calendarhover.item = gigObj;
                    this.$refs.calendarhover.tooltip = true;
                } else {
                    this.$refs.calendarhover.tooltip = false;
                }

                Object.assign(tooltip.style, {
                    top: `${event.pageY - 220}px`,
                    left: `${event.pageX - 260}px`,
                });

                if (event.pageY < 400) {
                    Object.assign(tooltip.style, {
                        top: `${event.pageY}px`,
                    });
                }
                if (event.pageY > 750) {
                    Object.assign(tooltip.style, {
                        top: `${event.pageY - 220}px`,
                    });
                }
                if (event.pageX > 840) {
                    Object.assign(tooltip.style, {
                        left: `${event.pageX - 700}px`,
                    });
                }
                if (event.pageX < 494) {
                    Object.assign(tooltip.style, {
                        left: `${event.pageX - 260}px`,
                    });
                }
            });
            this.isMounted = true;
            //this.fetchAndProcessGigs();
        }
        
    },
  emit: ['weekStartDayChanged']
};
</script>
<style scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    z-index: 10;
}
</style>